export function urlToBase64(url) {
    return new Promise((resolve, reject) => {
        let Img = new Image();
        let dataURL = "";
        Img.src = url + "?v=" + Math.random();
        Img.setAttribute("crossOrigin", "Anonymous");
        Img.onload = function () {
            let canvas = document.createElement("canvas");
            let width = Img.width;
            let height = Img.height;
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
            dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        };
    })
}

export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        }

    })
}

export function base64ToFile(dataUrl, fileName) {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}

export function urlToFile(dataUrl, fileName) {
    return new Promise((resolve,reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", dataUrl);
        xhr.setRequestHeader('Accept', 'image/png');
        xhr.responseType = "blob";
        xhr.onload = () => {
            const blob = xhr.response;
            resolve(new File([blob], fileName, { type: 'image/png' }));
        }
        xhr.onerror = (e) => {
            reject(e)
        };
        xhr.send();

    })
}

export function base64ToBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
        type: mime
    });
}

export function FileToBlob(file) {
    return new Promise((resolve,reject) => {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
            if (typeof e.target.result === 'object') {
                blob = new Blob([e.target.result])
            } else {
                blob = e.target.result
            }
            resolve(blob);
        }
    })
}

export function blobToDataURI(blob) {
    console.log(blob);
    return new Promise((resolve,reject) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            console.log(e);
            resolve(e.target.result);
        }
        reader.readAsDataURL(blob);
    })
    
}

export function parseFile(blob, fileName, flag = true) {
    if ('msSaveOrOpenBlob' in navigator) {
        //ie使用的下载方式
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.target = '_blank';
        if (flag) {
            a.download = fileName;
        }

        a.click();
        // window.URL.revokeObjectURL(url);
    }

}

export function openPDF(blob){
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.target = '_blank';
    a.click();
}